// src/components/_common/LocationMap/index.js
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import Image from 'next/image';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import { FaMap } from 'react-icons/fa';
import PropTypes from 'prop-types';

import styles from './css/location-map.module.css';

import { IconPaths } from '@/constants';
import { genAltText } from '@/utils';
import { useLanguage } from '@/contexts/LanguageContext';

import debugLog from '@/helpers/debugLog'; 

export const LocationMap = ({userCoordinates, latitudeLongitude, location, hdrFontSize = '24px', mapMarginTop = 'unset', btnMarginTop = '-46px'}) => {
    const [activeMarker, setActiveMarker] = useState(null);
    const [userLatitudeLongitude, setUserLatitudeLongitude] = useState(userCoordinates);
    const [mapActivity, setMapActivity] = useState(null);
    const intl = useIntl();
    const geoBookLocationIntl = intl.formatMessage({ id: "geoBookLocation" });
    const geoYourLocationIntl = intl.formatMessage({ id: "geoYourLocation" });
    const { locale } = useLanguage();

    // Użyj dostarczonych współrzędnych lub domyślnych, jeśli nie są dostępne
    const defaultCoordinates = useMemo(() => ({ 
        lat: 52.0693, 
        lng: 19.4803 
    }), []); // Centralna Polska
    
    const defaultZoom = 5; // Poziom zbliżenia dla całego kraju

    // Ustawienie współrzędnych centrum mapy
    const [center, setCenter] = useState({
        lat: defaultCoordinates.lat,
        lng: defaultCoordinates.lng
    });

    // Ustawienie poziomu zbliżenia
    const [zoom, setZoom] = useState(defaultZoom);

    const locationMarkers = useMemo(() => {
        const markerArray = [
            { 
                id: 1, 
                lat: latitudeLongitude.center.lat, 
                lng: latitudeLongitude.center.lng, 
                text: geoBookLocationIntl, 
                type: 'bookLocation' 
            },
            {   
                id: 2, 
                lat: userLatitudeLongitude.latitude, 
                lng: userLatitudeLongitude.longitude, 
                text: geoYourLocationIntl, 
                type: 'userLocation' },
            // { id: 2, lat: userLatitudeLongitude.latitude != undefined ? parseFloat(userLatitudeLongitude.latitude) : null, lng: userLatitudeLongitude.longitude != undefined ? parseFloat(userLatitudeLongitude.longitude) : null , text: geoYourLocationIntl, type: 'userLocation'},
        ];
        // console.log("LocationMap Generated markers:", markerArray);
        return markerArray.filter(marker => marker.lat != null && marker.lng != null);
    }, [latitudeLongitude, userLatitudeLongitude, geoBookLocationIntl, geoYourLocationIntl]);

    
    const averageCoordinates = useMemo(() => {
        const latitudes = locationMarkers.map(l => Number(l.lat));
        const longitudes = locationMarkers.map(l => Number(l.lng));

        let averageLat = (Math.max(...latitudes) + Math.min(...latitudes)) / 2;
        let averageLng = (Math.max(...longitudes) + Math.min(...longitudes)) / 2;

        if (locationMarkers.length === 1) {
            averageLat = locationMarkers[0].lat;
            averageLng = locationMarkers[0].lng;
        }

        return { averageLat, averageLng };
    }, [locationMarkers]);

    const mapContainerStyle = {
        width: "100%",
        minHeight: "298px",
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API
    })

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);

        setTimeout(() => {
            setActiveMarker(null);
        }, "4000");  
    };

    const handleShowOnMap = (latitude, longitude) => {
        // location.href=url;

        let url = 'https://maps.google.com/maps?directionsmode=driving&dirflg=d&daddr='+latitude+'+'+longitude+'&hl=pl”';
        window.open(
            url,
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    const onLoad = useCallback(function callback(map) {
        // Ustawienie poziomu zoomu na podstawie liczby markerów
        setZoom(locationMarkers.length > 1 ? 14 : defaultZoom);
        
        if ( locationMarkers.length > 1 ) {
            var bounds = new window.google.maps.LatLngBounds(center);
            locationMarkers.forEach((location) => {
                bounds.extend({ lat: Number(location.lat), lng: Number(location.lng) });
            })
            map.fitBounds(bounds); // <-------- resize based on markers, native 
            setMapActivity(map);
        }
    }, [locationMarkers, center]); //[locationMarkers, center, mapActivity]

    const onUnmount = useCallback((_map) => { // _map is not used but shows that the function could receive it
        debugLog('LocationMap onUnmount', 'debug', _map);
        setMapActivity(null);
    }, []);

    const getLocationAddress = useCallback((location, maxLength = 50) => {
        const parts = [
            location.street,
            location.city,
            location.state,
            location.postcode,
            location.country
        ];
        
        // Filtruje puste, null i undefined wartości
        const filteredParts = parts.filter(part => part);

        // Łączy części adresu
        const address = filteredParts.join(', ');

        // Sprawdza, czy pozostały jakiekolwiek części adresu; jeśli nie, zwraca alternatywny tekst
        if (filteredParts.length === 0) {
            return React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'Brak danych teleadresowych'); 
        }

        // Skraca adres, jeśli jest dłuższy niż maxLength
        return address.length > maxLength ? address.substring(0, maxLength) + '...' : address;
    }, []); 

    const getLocationName = useCallback((location, maxLength = 30) => {
        // Sprawdza, czy pozostały jakiekolwiek części nazwy lokalizacji; jeśli nie, zwraca alternatywny tekst
        if (location.name.length === 0) {
            return React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak nazwy lokalizacji'); 
        }
        return location.name.length > maxLength ? location.name.substring(0, maxLength) + '...' : location.name;
    }, []); 

    useEffect(() => {
        if (userCoordinates && userCoordinates.latitude && userCoordinates.longitude) {
            setUserLatitudeLongitude({
                latitude: userCoordinates.latitude,
                longitude: userCoordinates.longitude
            });
            setZoom(14);
        } else {
            setUserLatitudeLongitude({ 
                latitude: null, 
                longitude: null 
            });
        }
    }, [userCoordinates, zoom]);

    // Ustawienie współrzędnych jako centrum mapy
    useEffect(() => {
        // Sprawdzenie, czy istnieją jakiekolwiek markery
        if (locationMarkers.length > 0) {
            // Ustawienie średnich współrzędnych jako centrum mapy
            setCenter({
                lat: averageCoordinates.averageLat,
                lng: averageCoordinates.averageLng
            });
        } else {
            // Ustawienie domyślnych współrzędnych jako centrum mapy
            setCenter({
                lat: defaultCoordinates.lat,
                lng: defaultCoordinates.lng
            });
        }
    }, [averageCoordinates, locationMarkers, defaultCoordinates]);

    useEffect(() => {
        debugLog("LocationMap userCoordinates", 'debug', userCoordinates);
        debugLog("LocationMap userLatitudeLongitude", 'debug', userLatitudeLongitude);
        debugLog("LocationMap locationMarkers", 'debug', locationMarkers);
        debugLog('LocationMap mapActivity', 'debug', mapActivity);
    }, [userCoordinates, userLatitudeLongitude, locationMarkers, mapActivity]);

    return (
        <>
            <div className={styles["location-map--sec-l-mai"]}>
                <div className={styles["location-map--sec-l-mai-h"]}>
                    <h2 style={{fontSize: hdrFontSize}}>
                        <FaMap className={styles["location-map--ico"]}/>
                        <FormattedMessage
                            id = "Map"
                            defaultMessage="Mapa"
                        />
                    </h2>
                </div>
                <div style={{ height: '298px', width: '100%', marginTop: mapMarginTop}}>
                    { 
                        isLoaded ? 
                            <GoogleMap
                                mapContainerStyle={mapContainerStyle}
                                center={center}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                options={
                                    {
                                        mapTypeControl: false,
                                        streetViewControl: false,
                                        // controlSize: 30,
                                        // styles: mapStyles
                                    }
                                }
                                zoom={zoom}
                            >
                                {
                                    locationMarkers.map((marker, idx) => {
                                        return (     
                                            <Marker
                                                key={idx}
                                                id={marker.id}
                                                position={{
                                                    lat: Number(marker?.lat),
                                                    lng: Number(marker?.lng)
                                                }}
                                                title={marker.text}
                                                icon={{
                                                    url: marker.type === 'bookLocation' ? IconPaths.geoPointIcon : marker.type === 'userLocation' ? IconPaths.geoUserIcon : IconPaths.mapMarkerImage,
                                                    scaledSize: new window.google.maps.Size(32, 32)
                                                }}
                                                animation={{}}
                                                onClick={() => handleActiveMarker(marker.id)}
                                            >
                                                {  
                                                    activeMarker === marker.id ? (
                                                        <InfoWindow onCloseClick={() => setActiveMarker(null)} >
                                                            <div style={{maxWidth: '282px'}}>
                                                                {marker.text}
                                                                { 
                                                                    marker.type === 'bookLocation' ?
                                                                    <>
                                                                        <br />
                                                                        <b>{getLocationName(location, locale)}</b>
                                                                        <hr />
                                                                        <b>{getLocationAddress(location, 50)}</b>
                                                                    </> : 
                                                                    marker.type === 'userLocation' ?
                                                                    <>
                                                                        <br />
                                                                        {/* <b>{getLocationName(location, 50)}</b> */}
                                                                        {/* <hr /> */}
                                                                        {/* <b>{getLocationAddress(location, 50)}</b> */}
                                                                        { userLatitudeLongitude.latitude && userLatitudeLongitude.longitude &&
                                                                            <>
                                                                                <hr />
                                                                                <b>{userLatitudeLongitude.latitude + ', ' + userLatitudeLongitude.longitude}</b>
                                                                            </>
                                                                        }
                                                                    </> : ''
                                                                }
                                                            </div>
                                                        </InfoWindow>
                                                    ) : null
                                                }
                                            </Marker>
                                        )
                                    })
                                }
                            </GoogleMap>
                        : ''
                    }
                </div>
            </div>
            <div className={styles["location-map--sec-l-mai-som"]} style={{marginTop: btnMarginTop}}>
                <div className={styles["location-map--sec-l-mai-btn"]} style={{ cursor: 'pointer' }}>
                    <div className={styles["location-map--sec-l-mai-btn-f"]}>
                        <Image 
                            src={IconPaths.showOnMapIcon} 
                            alt={genAltText(IconPaths.showOnMapIcon)} 
                            width={24} height={'24'} 
                            className={styles["location-map--sec-l-mai-btn-ico"]} 
                        />
                    </div>
                    <div className={styles["location-map--sec-l-mai-btn-t"]} onClick={()=> {handleShowOnMap(latitudeLongitude.center.lat, latitudeLongitude.center.lng)}}>
                        <FormattedMessage 
                            id = "ShowOnMap"
                            defaultMessage="Pokaż na mapie" 
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

// PropTypes
LocationMap.propTypes = {
    userCoordinates: PropTypes.object,
    latitudeLongitude: PropTypes.object,
    location: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    hdrFontSize: PropTypes.string,
    mapMarginTop: PropTypes.string,
    btnMarginTop: PropTypes.string,
};