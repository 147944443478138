import React, { useEffect, useState, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { FaBook, FaAddressBook, FaHashtag, FaMap, FaCamera, FaRegCalendarAlt, FaBalanceScale, FaTrophy, FaInfoCircle, FaBuilding, FaMapMarkerAlt, FaPhone, FaEnvelope, FaGlobe, FaCreditCard, FaMoneyBillWave, FaMobileAlt, FaCar, FaParking, FaKey, FaRegClock, FaTruck, FaStore, FaTags } from 'react-icons/fa';
import { MdDirectionsBus, MdOutlineHistory } from 'react-icons/md';
import Image from 'next/image';
import { LocationMap } from '@/components/_common/LocationMap';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import styles from './css/location-details-modal-box.module.css';

import { Trans } from '@/utils/intlTranslations';
import { renderStatusIcon } from '@/utils';
import { ImagePaths, IconPaths } from '@/constants';
import { genAltText } from '@/utils';
import { useGeolocation } from '@/contexts/GeolocationContext';

export const LocationDetailsModalBox = ({ modalTitle, location, openModal, setOpenModal }) => {
    const { coordinates } = useGeolocation(); 
    const [latitudeLongitude, setLatitudeLongitude] = useState(
        {
            center: {
              lat: location?.latitude ? parseFloat(location.latitude): null,
              lng: location?.longitude ? parseFloat(location.longitude): null
            },
            zoom: 14
        }   
    );

    // Przygotowanie danych dla każdego dnia
    const days = [
        { name: "Monday", open: location.monday_open || '00:00', close: location.monday_close || '00:00' },
        { name: "Tuesday", open: location.tuesday_open || '00:00', close: location.tuesday_close || '00:00' },
        { name: "Wednesday", open: location.wednesday_open || '00:00', close: location.wednesday_close || '00:00' },
        { name: "Thursday", open: location.thursday_open || '00:00', close: location.thursday_close || '00:00' },
        { name: "Friday", open: location.friday_open || '00:00', close: location.friday_close || '00:00' },
        { name: "Saturday", open: location.saturday_open || '00:00', close: location.saturday_close || '00:00' },
        { name: "Sunday", open: location.sunday_open || '00:00', close: location.sunday_close || '00:00' },
    ];

    // Przygotowanie danych dla opcji dostaw
    const deliveryOptions = [
        { name: "pickup_only", value: location.pickup_only},
        { name: "no_pickup", value: location.no_pickup},
        { name: "poczta_polska", value: location.poczta_polska},
        { name: "gls", value: location.gls},
        { name: "dhl", value: location.dhl},
        { name: "orlen_paczka", value: location.orlen_paczka},
        { name: "inpost", value: location.inpost},
        { name: "cash_on_delivery", value: location.cash_on_delivery},
    ];

    const getLocationAddress = useCallback((location, maxLength = 50) => {
        const parts = [
            location.street,
            location.city,
            location.state,
            location.postcode,
            location.country
        ];
        
        // Filtruje puste, null i undefined wartości
        const filteredParts = parts.filter(part => part);

        // Łączy części adresu
        const address = filteredParts.join(', ');

        // Sprawdza, czy pozostały jakiekolwiek części adresu; jeśli nie, zwraca alternatywny tekst
        if (filteredParts.length === 0) {
            return React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'Brak danych teleadresowych'); 
        }

        // Skraca adres, jeśli jest dłuższy niż maxLength
        return address.length > maxLength ? address.substring(0, maxLength) + '...' : address;
    }, []); 

    const getLocationEmail = useCallback((location) => {
        return location.email || React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'Brak adresu email');
    }, []); 

    const getLocationImage = useCallback((location) => {
        return location.cover || location.picture || location.logo || ImagePaths.openCoverLargeImg;
      }, []);

    const getLatitudeLongitude = useCallback((location) => {
        location?.latitude && location?.longitude 
        ? 
        setLatitudeLongitude(
            {
                center: {
                  lat: parseFloat(location.latitude),
                  lng: parseFloat(location.longitude)
                },
                zoom: 14
            }
        )
        :
        setLatitudeLongitude(
            {
                center: {
                  lat: null,
                  lng: null
                },
                zoom: 5
            }
        )
    }, []); 

    const getLocationName = useCallback((location, maxLength = 30) => {
        // Sprawdza, czy pozostały jakiekolwiek części nazwy lokalizacji; jeśli nie, zwraca alternatywny tekst
        if (location?.name?.length === 0) {
            return React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak nazwy lokalizacji'); 
        }
        return location?.name?.length > maxLength ? location.name.substring(0, maxLength) + '...' : location.name;
    }, []); 

    const getLocationPhone = useCallback((location) => {
        // Sprawdza, czy pozostały jakiekolwiek części adresu; jeśli nie, zwraca alternatywny tekst
        return location.phone || React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'Brak numeru telefonu');
    }, []); 

    const getTagCategoryList = useCallback((categoriesTags, emptyMessage) => {
        // Sprawdza, czy categoriesTags jest pusty lub null
        if (!categoriesTags || categoriesTags.trim() === '') {
            return (
                <div className={styles["location-details--tags"]}>
                    <div className={styles["location-details--tags-t"]}>
                        {emptyMessage}
                    </div>
                </div>
            )
        }

        // Przekształcenie stringa na tablicę, oddzielając kategorie przecinkami
        const categoryArray = typeof categoriesTags === 'string' ? categoriesTags.split(',').map(cat => cat.trim()) : [categoriesTags];
    
        // Sprawdzenie, czy istnieją jakiekolwiek kategorie
        if (categoryArray.length > 0 && categoryArray[0] !== "") {
            return (
                <div className={styles["location-details--tags"]}>
                    {categoryArray.map((catName, index) => (
                        <div key={index} className={styles["location-details--tags-t"]}>
                            {/* Tutaj możesz wykonać wyszukiwanie lub mapowanie nazwy kategorii na odpowiedni obiekt, jeśli jest to wymagane */}
                            {catName}
                        </div>
                    ))}
                </div>
            );
        } 
    }, []);  

    const getLocationWww = useCallback((location) => {
        // Sprawdza, czy pozostały jakiekolwiek części adresu; jeśli nie, zwraca alternatywny tekst
        return location.www || React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'Brak adresu strony www');
    }, []); 

    useEffect(() => {
        if (location ) {
            getLatitudeLongitude(location)
        }
    }, [location, getLatitudeLongitude])

    return (
        <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-title"
            PaperProps={{
                style: {
                  maxHeight: '80vh',
                  marginBottom: '20vh', // Dostosuj wartość zgodnie z wysokością twojego menu
                },
            }}
        >
            <DialogTitle id="modal-title">{modalTitle}</DialogTitle>
            <DialogContent style={{ paddingBottom: '0px'}}>
                {/* Nazwa lokalizacji/Wydawca */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]}>
                        <h3>
                            <FormattedMessage
                                id = "BookLocationName"
                                defaultMessage="Nazwa lokalizacji"
                            />
                        </h3>
                        <div>
                            <span style={{marginRight: '-6px', color: '#40466a'}}>
                                <FormattedMessage
                                    id = "Publisher"
                                    defaultMessage="Wydawca"
                                />
                            </span>
                            {renderStatusIcon(location.is_publisher)}
                        </div> 
                    </div> 
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <FaStore className={styles["location-details--sec-wrp-g-i"]} />
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {getLocationName(location, 100)}
                            </div>
                        </div> 
                    </div>      
                </div>

                <hr />

                {/* Cover/Picture/Logo */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]} style={{justifyContent: "flex-start", alignItems: 'flex-start'}}>
                        <FaCamera className={styles["location-details--ico"]}/>
                        <h3>
                            <FormattedMessage
                                id = "Picture"
                                defaultMessage="Zdjęcie"
                            />
                        </h3>
                    </div> 
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {/* <Image 
                                    src={getLocationImage(location)} 
                                    alt={genAltText(getLocationImage(location))} 
                                    width={412} height={412} 
                                    className={styles["location--img"]} 
                                /> */}
                                {/* <img 
                                    src={getLocationImage(location)} 
                                    alt={genAltText(getLocationImage(location))} 
                                    width={412} height={412} 
                                    className={styles["location--img"]} 
                                /> */}
                                <LazyLoadImage
                                    key={location.id}
                                    src={getLocationImage(location)} // źródło obrazu
                                    alt={genAltText(getLocationImage(location))} // tekst alternatywny
                                    width={412} 
                                    // height={549}
                                    height='auto'
                                    effect="blur" // efekt ładowania (opcjonalnie)
                                    placeholderSrc={ImagePaths.locationBlurImg} // Tutaj umieść ścieżkę do uniwersalnego rozmytego obrazka
                                    className={styles["location--img"]} 
                                />
                            </div>
                        </div>
                    </div> 
                </div>

                <hr />

                {/* Kontakt */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]} style={{justifyContent: "flex-start", alignItems: 'flex-start'}}>
                        {/* <FaAddressBook className={styles["location-details--ico"]} style={{width: "20px"}}/> */}
                        <h3>
                            <FormattedMessage
                                id = "Contact"
                                defaultMessage="Kontakt"
                            />
                        </h3>
                    </div> 
                    {/* Address */}
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <FaMapMarkerAlt className={styles["ocation-details--sec-wrp-g-i"]}/>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {getLocationAddress(location, 80)}
                            </div>
                        </div> 
                    </div>  
                    {/* Phone */}
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <FaPhone className={styles["ocation-details--sec-wrp-g-i"]}/>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {getLocationPhone(location, 80)}
                            </div>
                        </div> 
                    </div> 
                    {/* Email */}
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <FaEnvelope className={styles["ocation-details--sec-wrp-g-i"]}/>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {getLocationEmail(location, 80)}
                            </div>
                        </div> 
                    </div> 
                    {/* www */}
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <FaGlobe className={styles["ocation-details--sec-wrp-g-i"]}/>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {getLocationWww(location, 80)}
                            </div>
                        </div> 
                    </div> 
                </div>

                <hr />

                {/* Description */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]} style={{justifyContent: "flex-start", alignItems: 'flex-start'}}>
                        <FaBuilding className={styles["location-details--ico"]}/>
                        <h3>
                            <FormattedMessage
                                id = "Description"
                                defaultMessage="Opis"
                            />
                        </h3>
                    </div> 
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {
                                    location.description ?
                                        <div dangerouslySetInnerHTML={{__html: location.description }}/>
                                    :
                                    React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych') 
                                }
                            </div>
                        </div>
                    </div> 
                </div>

                <hr />

                {/* Dodatkowe informacje */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]} style={{justifyContent: "flex-start", alignItems: 'flex-start'}}>
                        <FaInfoCircle className={styles["location-details--ico"]}/>
                        <h3>
                            <FormattedMessage
                                id = "AdditionalInformation"
                                defaultMessage="Dodatkowe informacje"
                            />
                        </h3>
                    </div> 
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {
                                    location.additional_info ?
                                        <div dangerouslySetInnerHTML={{__html: location.additional_info }}/>
                                    :
                                    React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych') 
                                }
                            </div>
                        </div>
                    </div> 
                </div>

                <hr />

                {/* Podstawowe informacje nt. lokalizacji */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]} style={{justifyContent: "flex-start", alignItems: 'flex-start'}}>
                        <FaInfoCircle className={styles["location-details--ico"]}/>
                        <h3>
                            <FormattedMessage
                                id = "GeneralInfo"
                                defaultMessage="Podstawowe informacje nt. lokalizacji"
                            />
                        </h3>
                    </div> 
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {
                                    location.general_info ?
                                        <div dangerouslySetInnerHTML={{__html: location.general_info }}/>
                                    :
                                    React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych') 
                                }
                            </div>
                        </div>
                    </div> 
                </div>
                
                <hr />

                {/* LocationMap */}
                <div className={styles["location-detail--sec"]}>
                    <LocationMap userCoordinates={coordinates} latitudeLongitude={latitudeLongitude} location={location} hdrFontSize={'18px'} mapMarginTop = {'-14px'} btnMarginTop={'10px'}/>
                </div>

                <hr />

                {/* Godziny otwarcia */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]} style={{justifyContent: "flex-start", alignItems: 'flex-start'}}>
                        <FaRegClock className={styles["location-details--ico"]}/>
                        <h3>
                            <FormattedMessage
                                id = "OpeningHours"
                                defaultMessage="Godziny otwarcia"
                            />
                        </h3>
                    </div> 
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                <div className={styles["location-details--ohp-sec"]}>
                                    {days.map((day) => (
                                        <div key={day.name}>
                                            <FormattedMessage
                                                id={day.name}
                                                defaultMessage={Trans(day.name)}
                                            />
                                            <div className={styles["location-details--ohp-sec-item"]}>
                                                <div>
                                                    {day.open} - {day.close}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div> 
                    </div>      
                </div>

                {/* Czynne całą dobę/Zamknięte na stałe */}
                <div className={styles["location-detail--sec"]} style={{marginTop: '14px'}}>
                    <div className={styles["location-details--sec-wrp"]}>
                        {/* is_always_open */}
                        <div className={styles["opening-hours--sec-checkbox"]}>
                            <div className={styles["opening-hours--sec-checkbox-d"]}>
                                <span style={{marginRight: '-6px', color: '#40466a'}}>
                                    <FormattedMessage
                                        id = "is_always_open"
                                        defaultMessage="Czynne całą dobę"
                                    />
                                </span>
                                {renderStatusIcon(location.is_always_open)}
                            </div>
                        </div>
                        {/* is_permanently_closed */}
                        <div className={styles["opening-hours--sec-checkbox"]}>
                            <div className={styles["opening-hours--sec-checkbox-d"]}>
                                <span style={{marginRight: '-6px', color: '#40466a'}}>
                                    <FormattedMessage
                                        id = "is_permanently_closed"
                                        defaultMessage="Zamknięte na stałe"
                                    />
                                </span>
                                {renderStatusIcon(location.is_permanently_closed)}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Tymczasowy status */}
                <div className={styles["location-detail--sec"]} style={{marginTop: '14px'}}>
                    <div className={styles["location-details--sec-wrp"]}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {/* temporary_status */}
                            <div className={styles["location-details--hdr"]}>
                                <FormattedMessage
                                    id = "temporary_status"
                                    defaultMessage="Tymczasowy status"
                                />
                            </div>
                            <div className={styles["opening-hours--sec-txt"]}>
                                { 
                                    location.temporary_status != '' ?
                                        
                                        <div dangerouslySetInnerHTML={{__html: location.temporary_status }}/>
                                    :
                                        React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'Brak danych') 
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                {/* Opcje dostawy */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]} style={{justifyContent: "flex-start", alignItems: 'flex-start'}}>
                        <FaTruck className={styles["location-details--ico"]}/>
                        <h3>
                            <FormattedMessage
                                id = "DeliveryOptions"
                                defaultMessage="Opcje dostawy"
                            />
                        </h3>
                    </div> 
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                <div className={styles["location-details--ohp-sec"]}>
                                {
                                    deliveryOptions.map((item) => (
                                        <div key={item.name}>
                                            <div className={styles["location-details--do-sec-item"]}>
                                                <div>
                                                    <FormattedMessage
                                                        id={item.name}
                                                        defaultMessage={Trans(item.name)}
                                                    />
                                                    {renderStatusIcon(item.value)}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div> 
                    </div>      
                </div>

                <hr />

                {/* Jak dojechać */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]} style={{justifyContent: "flex-start", alignItems: 'flex-start'}}>
                        <MdDirectionsBus className={styles["location-details--ico"]}/>
                        <h3>
                            <FormattedMessage
                                id = "HowToGet"
                                defaultMessage="Opcje dostawy"
                            />
                        </h3>
                    </div> 
                    <div className={styles["location-details--hdr"]}>
                        <h4>
                            <FormattedMessage
                                id = "public_transit"
                                defaultMessage="Dojazd komunikacją publiczną"
                            />
                        </h4>
                    </div> 
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {
                                    location.public_transit ?
                                        <div dangerouslySetInnerHTML={{__html: location.public_transit }}/>
                                    :
                                    React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych') 
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles["location-details--hdr"]}>
                        <h4>
                            <FormattedMessage
                                id = "public_transit_description"
                                defaultMessage="Informacje dotyczące dojazdu"
                            />
                        </h4>
                    </div>
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {
                                    location.public_transit_description ?
                                        <div dangerouslySetInnerHTML={{__html: location.public_transit_description }}/>
                                    :
                                    React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych') 
                                }
                            </div>
                        </div>
                    </div>   
                </div>

                <hr />

                {/* Płatności */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]}>
                        <h3>
                            <FormattedMessage
                                id = "Payments"
                                defaultMessage="Płatności"
                            />
                        </h3>
                    </div> 
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {/* Gotówką */}
                                <div className={styles["location-details--payments"]} style={{marginBottom: "8px"}}>
                                    <FaMoneyBillWave className={styles["location-details--ico"]}/>
                                    <div className={styles["location-details--payments-d"]}>
                                        <FormattedMessage id = "payment_cash_only" defaultMessage="payment_cash_only"/>
                                            {renderStatusIcon(location.payment_cash_only)}
                                    </div>
                                </div> 

                                {/* Blik */}
                                <div className={styles["location-details--payments"]} style={{marginBottom: "8px"}}>
                                    <FaMobileAlt className={styles["location-details--ico"]}/>
                                    <div className={styles["location-details--payments-d"]}>
                                        <FormattedMessage id = "payment_blik" defaultMessage="payment_blik"/>
                                            {renderStatusIcon(location.payment_blik)}
                                    </div>
                                </div> 

                                {/* Visa */}
                                <div className={styles["location-details--payments"]} style={{marginBottom: "8px"}}>
                                    <FaCreditCard className={styles["location-details--ico"]}/>
                                    <div className={styles["location-details--payments-d"]}>
                                        <FormattedMessage id = "payment_visa" defaultMessage="payment_visa"/>
                                            {renderStatusIcon(location.payment_visa)}
                                    </div>
                                </div> 

                                {/* Mastercard */}
                                <div className={styles["location-details--payments"]} style={{marginBottom: "8px"}}>
                                    <FaCreditCard className={styles["location-details--ico"]}/>
                                    <div className={styles["location-details--payments-d"]}>
                                        <FormattedMessage id = "payment_mastercard" defaultMessage="payment_mastercard"/>
                                            {renderStatusIcon(location.payment_mastercard)}
                                    </div>
                                </div> 

                                {/* American Express */}
                                <div className={styles["location-details--payments"]}>
                                    <FaCreditCard className={styles["location-details--ico"]}/>
                                    <div className={styles["location-details--payments-d"]}>
                                        <FormattedMessage id = "payment_amex" defaultMessage="payment_amex"/>
                                            {renderStatusIcon(location.payment_amex)}
                                    </div>
                                </div> 
                            </div> 
                        </div> 
                    </div>      
                </div>

                <hr />

                {/* Parking */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]}>
                        <h3>
                            <FormattedMessage
                                id = "Parking"
                                defaultMessage="Parking"
                            />
                        </h3>
                    </div> 
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {/* Parking na ulicy */}
                                <div className={styles["location-details--payments"]} style={{marginBottom: "8px"}}>
                                    <FaCar className={styles["location-details--ico"]}/>
                                    <div className={styles["location-details--payments-d"]}>
                                        <FormattedMessage id = "parking_street" defaultMessage="parking_street"/>
                                            {renderStatusIcon(location.parking_street)}
                                    </div>
                                </div> 

                                {/* Dedykowany parking */}
                                <div className={styles["location-details--payments"]} style={{marginBottom: "8px"}}>
                                    <FaParking className={styles["location-details--ico"]}/>
                                    <div className={styles["location-details--payments-d"]}>
                                        <FormattedMessage id = "parking_lot" defaultMessage="parking_lot"/>
                                            {renderStatusIcon(location.parking_lot)}
                                    </div>
                                </div> 

                                {/* Parking typu valet */}
                                <div className={styles["location-details--payments"]} style={{marginBottom: "8px"}}>
                                    <FaKey className={styles["location-details--ico"]}/>
                                    <div className={styles["location-details--payments-d"]}>
                                        <FormattedMessage id = "parking_valet" defaultMessage="parking_valet"/>
                                            {renderStatusIcon(location.parking_valet)}
                                    </div>
                                </div> 
                            </div> 
                        </div> 
                    </div>      
                </div>

                <hr />

                {/* Osoba kontaktowa */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]} style={{justifyContent: "flex-start", alignItems: 'flex-start'}}>
                        <FaInfoCircle className={styles["location-details--ico"]}/>
                        <h3>
                            <FormattedMessage
                                id = "GeneralManager"
                                defaultMessage="Osoba kontaktowa"
                            />
                        </h3>
                    </div> 
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {
                                    location.general_manager ?
                                        <div dangerouslySetInnerHTML={{__html: location.general_manager }}/>
                                    :
                                    React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych') 
                                }
                            </div>
                        </div>
                    </div> 
                </div>

                <hr />

                {/* Nagrody */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]} style={{justifyContent: "flex-start", alignItems: 'flex-start'}}>
                        <FaTrophy className={styles["location-details--ico"]}/>
                        <h3>
                            <FormattedMessage
                                id = "Awards"
                                defaultMessage="Nagrody"
                            />
                        </h3>
                    </div> 
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {
                                    location.awards ?
                                        <div dangerouslySetInnerHTML={{__html: location.awards }}/>
                                    :
                                    React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych') 
                                }
                            </div>
                        </div>
                    </div> 
                </div>

                <hr />

                {/* Założenie firmy */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]} style={{justifyContent: "flex-start", alignItems: 'flex-start'}}>
                        <FaRegCalendarAlt className={styles["location-details--ico"]}/>
                        <h3>
                            <FormattedMessage
                                id = "Founded"
                                defaultMessage="Założenie firmy"
                            />
                        </h3>
                    </div> 
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {
                                    location.founded ?
                                        <div dangerouslySetInnerHTML={{__html: location.founded }}/>
                                    :
                                    React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych') 
                                }
                            </div>
                        </div>
                    </div> 
                </div>

                <hr />

                {/* Data rozpoczęcia */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]} style={{justifyContent: "flex-start", alignItems: 'flex-start'}}>
                        <MdOutlineHistory className={styles["location-details--ico"]}/>
                        <h3>
                            <FormattedMessage
                                id = "StartInfo"
                                defaultMessage="Data rozpoczęcia"
                            />
                        </h3>
                    </div> 
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {
                                    location.start_info ?
                                        <div dangerouslySetInnerHTML={{__html: location.start_info }}/>
                                    :
                                    React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych') 
                                }
                            </div>
                        </div>
                    </div> 
                </div>

                <hr />

                {/* Nota prawna */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]} style={{justifyContent: "flex-start", alignItems: 'flex-start'}}>
                        <FaBalanceScale className={styles["location-details--ico"]}/>
                        <h3>
                            <FormattedMessage
                                id = "Impressum"
                                defaultMessage="Nota prawna"
                            />
                        </h3>
                    </div> 
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {
                                    location.impressum ?
                                        <div dangerouslySetInnerHTML={{__html: location.impressum }}/>
                                    :
                                    React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych') 
                                }
                            </div>
                        </div>
                    </div> 
                </div>

                <hr />

                {/* Social Media */}
                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--sm"]}>
                        <div className={styles["location-details--fb"]}>
                            <a href={location.facebook_link} alt={'facebook'}  target="_blank" rel="noopener noreferrer">
                                <Image 
                                    src={IconPaths.facebookDetailsIcon} 
                                    alt={genAltText(IconPaths.facebookDetailsIcon)} 
                                    width={24} height={24} 
                                    className={styles["location-details--fb-ico"]} 
                                    style={{opacity: location.facebookDetailsIcon ? '1' : '0.3' }} 
                                />
                            </a>
                        </div>
                        <div className={styles["location-details--fbmw"]}>
                            <a href={location.messenger} alt={'messenger'} target="_blank" rel="noopener noreferrer">
                                <Image 
                                    src={IconPaths.facebookMessengerIcon} 
                                    alt={genAltText(IconPaths.facebookMessengerIcon)} 
                                    width={24} height={24}
                                    style={{opacity: location.messenger ? '1' : '0.3' }} 
                                />
                            </a>
                        </div>
                        <div className={styles["location-details--fbmw"]}>
                            <a href={location.whatsapp} alt={'whatsapp'} target="_blank" rel="noopener noreferrer">
                                <Image 
                                    src={IconPaths.whatsappIcon} 
                                    alt={genAltText(IconPaths.whatsappIcon)} 
                                    width={24} height={24} 
                                    style={{opacity: location.whatsapp ? '1' : '0.3' }}
                                />
                            </a>
                        </div>
                        <div className={styles["location-details--fbmw"]}>
                            <a href={location.insta_url} alt={location.insta_name} target="_blank" rel="noopener noreferrer">
                                <Image 
                                    src={IconPaths.instagramIcon} 
                                    alt={genAltText(IconPaths.instagramIcon)} 
                                    width={24} height={24} 
                                    style={{opacity: location.insta_url ? '1' : '0.3' }}
                                />
                            </a>
                        </div>
                        <div className={styles["location-details--fbmw"]}>
                            <a href={location.fsqr_url} alt={location.fsqr_name} target="_blank" rel="noopener noreferrer">
                                <Image 
                                    src={IconPaths.foursquareIcon} 
                                    alt={genAltText(IconPaths.foursquareIcon)} 
                                    width={24} height={24} 
                                    style={{opacity: location.fsqr_url ? '1' : '0.3' }}
                                />
                            </a>
                        </div>
                        <div className={styles["location-details--fbmw"]}>
                            <a href={location.yelp_url} alt={location.yelp_name} target="_blank" rel="noopener noreferrer">
                                <Image 
                                    src={IconPaths.yelpIcon} 
                                    alt={genAltText(IconPaths.yelpIcon)} 
                                    width={24} height={24} 
                                    style={{opacity: location.yelp_url ? '1' : '0.3' }}
                                />
                            </a>
                        </div>
                        <div className={styles["location-details--fbmw"]}>
                            <a href={location.tiktok_url} alt={'tiktok'} target="_blank" rel="noopener noreferrer">
                                <Image 
                                    src={IconPaths.tiktokIcon} 
                                    alt={genAltText(IconPaths.tiktokIcon)} 
                                    width={24} height={24} 
                                    style={{opacity: location.tiktok_url ? '1' : '0.3' }}
                                />
                            </a>
                        </div>
                        <div className={styles["location-details--fbmw"]}>
                            <a href={location.glovo_url} alt={'glovo'} target="_blank" rel="noopener noreferrer">
                                <Image 
                                    src={IconPaths.glovoIcon} 
                                    alt={genAltText(IconPaths.glovoIcon)} 
                                    width={24} height={24}
                                    style={{opacity: location.glovo_url ? '1' : '0.3' }} 
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <hr />

                <div className={styles["location-detail--sec"]}>
                    <div className={styles["location-details--hdr"]} style={{justifyContent: "flex-start", alignItems: 'flex-start'}}>
                        <FaTags className={styles["location-details--ico"]}/>
                        <h3>
                            <FormattedMessage
                                id = "Tags"
                                defaultMessage="Tagi"
                            />
                        </h3>
                    </div> 
                    <div className={styles["location-details--sec-wrp"]}>
                        <div className={styles["location-details--sec-wrp-g"]}>
                            <div className={styles["location-details--sec-wrp-g-d"]}>
                                {getTagCategoryList(location.tags, 'brak tagów')}
                            </div> 
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenModal(false)}>Zamknij</Button>
            </DialogActions>
        </Dialog>
    );
}

// PropTypes
LocationDetailsModalBox.propTypes = {
    modalTitle: PropTypes.string,
    location: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
  // Definicje dla pozostałych właściwości...
};